window.menuFrontend = function () {

  $(".navbar .openMenu").on('click', function () {
    $("#mySidenav, .sticky-menu-items, #mySidenav .closebtn").fadeIn(1000);

    $(document).mouseup(function (e) {
      var container = $("#mySidenav");
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        $("#mySidenav, #mySidenav .closebtn").fadeOut(1000);
      }
    });
  });

  $("#mySidenav .closebtn").on('click', function () {
    $("#mySidenav, #mySidenav .closebtn").fadeOut(1000);
  });

  $('#mySidenav a[href*="#"]').on('click', function () {
    $("#mySidenav, #mySidenav .closebtn").fadeOut(1000);
  });
}
